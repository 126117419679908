<template>
    <v-container fluid>
        <v-dialog
            v-model="sampleTestsDialog"
            max-width="500px"
            :fullscreen="$vuetify.breakpoint.xsOnly"
            persistent>
            <sample-tests-form
                :key="sampleTestsKey"
                :sample="editedSample"
                @close="sampleTestsDialog = false" />
        </v-dialog>
        <v-row>
            <v-col>
                <table-search :search.sync="search" />
            </v-col>
            <v-col>
                <v-switch v-model="showCompletedSamples" label="Show Completed Samples" />
            </v-col>
            <v-col>
                <table-buttons
                    :add-dialog.sync="addDialog"
                    :no-data-dialog.sync="noDataDialog"
                    :form-title="tableSchema.formTitle"
                    :claim-name="tableSchema.claimName"
                    :is-add-disabled="isAddDisabled"
                    @download="download()"
                    @addItem="addItem()">
                    <template v-slot:form="">
                        <sample-form
                            :key="formKey"
                            v-model="editedItem"
                            :schema="getSchema(tableSchema.claimName)"
                            :filter="tableFilter"
                            @cancel="addDialog = false"
                            @open="addDialog = true"
                            @input="onInput()"
                            @delete="onDelete" />
                    </template>
                </table-buttons>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <table-layout
                    :get-slot-name="getSlotName"
                    :table-height="getTableHeight"
                    :items-per-page="getItemsPerPage"
                    :custom-sort="customSort"
                    :headers="filteredHeaders"
                    :items="computedItems"
                    :footer-props="footerProps"
                    :options.sync="options"
                    :item-total="itemTotal"
                    :claim-name="tableSchema.claimName"
                    :show-expand="showExpand"
                    :has-edit-page="hasEditPage"
                    :is-loading="isLoading"
                    @goToEditPage="goToEditPage()">
                    <template v-slot:tableCell="{ header, item }">
                        <table-cell
                            :header="header"
                            :item="item"
                            :download-file="downloadFile"
                            :go-to-table="goToTable"
                            :api-data="apiData"
                            :filter="tableFilter" />
                    </template>
                    <template v-slot:tableActions="{ item }">
                        <table-cell-actions
                            :item="item"
                            :claim-name="tableSchema.claimName"
                            :is-edit-disabled="isEditDisabled"
                            :has-edit-page="hasEditPage"
                            @editItem="editItem"
                            @goToEditPage="goToEditPage">
                            <template v-slot:cellButtons="">
                                <v-btn @click="viewTests(item)">
                                    Tests
                                </v-btn>
                            </template>
                        </table-cell-actions>
                    </template>
                </table-layout>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import { equal, and } from "@/services/filtering"

export default {
    components: {
        tableLayout: () => import("@/components/TableLayout"),
        tableCell: () => import("@/components/TableCell"),
        tableCellActions: () => import("@/components/TableCellActions"),
        tableButtons: () => import("@/components/TableButtons"),
        tableSearch: () => import("@/components/TableSearch"),
        sampleForm: () => import("@/forms/SampleForm"),
        sampleTestsForm: () => import("@/views/labTestDashboard/SampleTestsForm")
    },
    mixins: [tableMixin],
    data() {
        return {
            editedSample: null,
            sampleTestsDialog: false,
            sampleTestsKey: 0,
            showCompletedSamples: false
        };
    },
    watch: {
        showCompletedSamples() {
            this.load();
        }
    },
    methods: {
        viewTests(sample) {
            this.editedSample = sample;
            this.sampleTestsKey++;
            this.sampleTestsDialog = true;
        },

        setFilters(params, schema) {
            let filters = [
                equal("IsTestingCompleted", this.showCompletedSamples, "bool")
            ];
            for (let property in this.tableFilter) {
                if (Object.prototype.hasOwnProperty.call(this.tableFilter, property)) {
                    let field = schema.fields.filter(e => e.camelSingular === property);
                    if (this.isEmpty(field) || field.length !== 1) {
                        continue;
                    }
                    field = field[0];
                    let filter = equal(property, this.tableFilter[property], field.nullableType);
                    filters.push(filter);
                }
            }
            if (!this.isEmpty(this.customFilter)) {
                filters.push(this.customFilter)
            }
            if (filters.length > 0) {
                if (!this.isEmpty(params.filter)) {
                    filters.push(params.filter);
                }
                params.filter = and(filters);
            }
        },
    }
};
</script>
